import React from "react"
import { Link, graphql } from "gatsby"

import SEO from "../components/seo"
import { MHPageProps } from "../types"
import { PostListForMDX } from "../components/PostList"

class TagIndex extends React.Component<MHPageProps> {
  render() {
    const { data } = this.props
    const { tag } = this.props.pageContext

    return (
      <>
        <SEO
          title={`Posts with tag “${tag}”`}
          description={`All the content on my website that was filed under ${tag}`}
        />

        <h1 className="n-text-centered n-vspacing-large">{`Posts with tag “${tag}”`}</h1>
        <PostListForMDX posts={data.allMdx.edges} />
      </>
    )
  }
}

export default TagIndex

export const pageQuery = graphql`
  query PostsByTag($tag: String, $publishedFilter: BooleanQueryOperatorInput) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: [DESC] }
      filter: {
        frontmatter: { tags: { in: [$tag] }, published: $publishedFilter }
      }
    ) {
      totalCount

      edges {
        node {
          id
          ...MHExcerpt
          fields {
            slug
            contentType
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            byline
            audio
            published
          }
          wordCount {
            # For measuring if this page should have a permanent link
            words
          }
        }
      }
    }
  }
`
